<template>
  <section>
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm1"
          :model="selectForm1"
          class="select_form"
        >
          <el-form-item label="姓名:">
            <el-input v-model="selectForm1.name"></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="new_css calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
     
        @current-change="selectCurrentRow"
        @row-dblclick="handleEdit"
        class="new_table"
      >
        <el-table-column prop="CreateUserName" label="创建人">
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="CreateDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column label="年" prop="Year"></el-table-column>
        <el-table-column label="月" prop="Month"></el-table-column>
        <el-table-column label="状态" prop="State">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.State == '未锁定' ? 'success' : 'danger'"
              disable-transitions
            >
              {{ scope.row.State }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog title="编辑" :visible.sync="SakaryVisible" v-model="SakaryVisible" :close-on-click-modal="false">
        <el-form ref="SakaryForm" :model="SakaryForm" class="demo-form-inline" label-width="150px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="名称:" prop="UserName" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-input type="text" v-model="SakaryForm.UserName" :disabled="true"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="缴费基数:" prop="BaseSalary" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-input type="text" v-model="SakaryForm.BaseSalary" @blur="inputMoney('BaseSalary')" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="养老保险(公司):" prop="Factory1" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-input type="text" v-model="SakaryForm.Factory1" @blur="inputMoney('Factory1')">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="养老保险(个人):" prop="User1" :rules="[ { required: true, message: '不能为空', trigger: 'blur' },]">
                        <el-input type="text" v-model="SakaryForm.User1" @blur="inputMoney('User1')"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="医疗保险(公司):" prop="Factory2" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                        <el-input type="text" v-model="SakaryForm.Factory2" @blur="inputMoney('Factory2')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="医疗保险(个人):" prop="User2" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                        <el-input type="text" v-model="SakaryForm.User2" @blur="inputMoney('User2')"> </el-input>
                    </el-form-item>
                </el-col>


            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="失业保险(公司):" prop="Factory3" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                        <el-input type="text" v-model="SakaryForm.Factory3" @blur="inputMoney('Factory3')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="失业保险(个人):" prop="User3" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                        <el-input type="text" v-model="SakaryForm.User3" @blur="inputMoney('User3')"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="工伤保险(公司):" prop="Factory4" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                        <el-input type="text" v-model="SakaryForm.Factory4" @blur="inputMoney('Factory4')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="工伤保险(个人):" prop="User4" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-input type="text" v-model="SakaryForm.User4" @blur="inputMoney('User4')"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="生育保险(公司):" prop="Factory5" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-input type="text" v-model="SakaryForm.Factory5" @blur="inputMoney('Factory5')"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="生育保险(个人):" prop="User5" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-input type="text" v-model="SakaryForm.User5" @blur="inputMoney('User5')"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="大额医疗(公司):" prop="FactoryLarge" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-input type="text" v-model="SakaryForm.FactoryLarge" @blur="inputMoney('FactoryLarge')"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="大额医疗(个人):" prop="UserLarge" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-input type="text" v-model="SakaryForm.UserLarge" @blur="inputMoney('UserLarge')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    
                </el-col>
                <el-col :span="12">
                    <el-form-item label="总缴费(个人):" prop="UserActualTotal" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-input type="text" v-model="SakaryForm.UserActualTotal" @blur="inputMoney('UserActualTotal')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="text-align: center">

                    <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>

                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
    <el-dialog
      title="社保五险记录"
      :visible.sync="InsuranceVisible"
      v-model="InsuranceVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
        <el-col
          v-if="buttonList.length > 0"
          :span="24"
          class="organizetoolbar"
          style="padding-bottom: 0px"
        >
          <el-form :inline="true" ref="selectForm" :model="selectForm">
            <el-form-item label="名称:">
              <el-input
                v-model="selectForm.userName"
                placeholder="名称"
              ></el-input>
            </el-form-item>
            <!-- 这个就是当前页面内，所有的btn列表 -->
            <el-form-item>
              <!-- 这里触发点击事件 -->

              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="getInsuranceData()"
                >查询</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="isDisabled"
                @click="handleInEdit()"
                >修改</el-button
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="不勾选人员默认修改全部"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-edit"
                  :disabled="isDisabled"
                  @click="handleAllEdit()"
                  >批量修改</el-button
                >
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table :data="InsuranceData"
                highlight-current-row
                border
                height="500"
                @current-change="selectCurrentRow1"
                @selection-change="handleSelectionChange"
                style="width: 100%">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="UserName" width="150" label="名称" fixed></el-table-column>
          <el-table-column prop="IDCard" width="200" label="身份证号" fixed></el-table-column>

          <el-table-column prop="Year" width="120" label="年"> </el-table-column>
          <el-table-column prop="Month" width="120" label="月"> </el-table-column>
          <el-table-column label="缴费基数" width="120" prop="BaseSalary"></el-table-column>
          <el-table-column label="缴费单位" prop="PaymentAccount"></el-table-column>
          <el-table-column label="养老保险(公司)" width="120" prop="Factory1"></el-table-column>
          <el-table-column label="医疗保险(公司)" width="120" prop="Factory2"></el-table-column>
          <el-table-column label="失业保险(公司)" width="120" prop="Factory3"></el-table-column>
          <el-table-column label="工伤保险(公司)" width="120" prop="Factory4"></el-table-column>
          <el-table-column label="生育保险(公司)" width="120" prop="Factory5"></el-table-column>
          <el-table-column label="大额医疗(公司)" width="120" prop="FactoryLarge"></el-table-column>
          <el-table-column label="养老保险(个人)" width="120" prop="User1"></el-table-column>
          <el-table-column label="医疗保险(个人)" width="120" prop="User2"></el-table-column>
          <el-table-column label="失业保险(个人)" width="120" prop="User3"></el-table-column>
          <el-table-column label="工伤保险(个人)" width="120" prop="User4"></el-table-column>
          <el-table-column label="生育保险(个人)" width="120" prop="User5"></el-table-column>
          <el-table-column label="大额医疗(个人)" width="150" prop="UserLarge"></el-table-column>
          <el-table-column label="总缴费(个人)" width="150" prop="UserActualTotal" fixed="right"></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-size="pageSize1"
        layout="prev, pager, next, jumper ,total"
        :page-count="total1"
        :total="totaldata1"
      >
      </el-pagination>
    </el-dialog>
    <el-dialog
      title="编辑"
      :visible.sync="SakaryAllFormVisible"
      v-model="SakaryAllFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryAllForm"
        :model="SakaryAllForm"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="大额医疗(公司):"
              prop="FactoryLarge"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryAllForm.FactoryLarge"
                @blur="inputAllMoney('FactoryLarge')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="大额医疗(个人):"
              prop="UserLarge"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryAllForm.UserLarge"
                @blur="inputAllMoney('UserLarge')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
           
              <el-button
                type="primary"
                v-on:click="onAllSubmit('SakaryAllForm')"
                >提交</el-button
              >
         
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
//  import moment from 'moment/moment.js'
import * as validate from "../../../util/validate";
import {
  GetInsuranceMonthPageList,
  AddInsuranceMonth,
  DeleteInsuranceMonth,
  GetInsuranceReportPageList,
  UpdateInsuranceReport,
  UpdateSetInfoSate,
  GetPersonAddExcelList,
  GetPersonReduceExcelList,
  GetPersonHistoryList,
  UpdateInsuranceReportSalary,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      loading: true,
      searchVal: "",

      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      isDisabled: false,
      SakaryVisible: false,
      InsuranceVisible: false,
      SakaryAllFormVisible: false,
      buttonListmsg: "",
      users: [],
      multipleSelection: [],
      InsuranceData: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      PId: "",
      selectForm: {
        userName: "",
      },
      selectForm1: {
        name: "",
      },
      SakaryAllForm: {
        FactoryLarge: "",
        UserLarge: "",
        PId: "",
      },
      SakaryForm: [],
      filters: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getInsuranceData(false);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm1.name,
      };
      console.log(para);
      GetInsuranceMonthPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getInsuranceData(isCurrent = true) {
      if (isCurrent) {
        this.page1 = 1;
      }
      let para = {
        page: this.page1,
        size: this.pageSize1,
        PId: this.PId,
        userName: this.selectForm.userName,
      };
      GetInsuranceReportPageList(para).then((res) => {
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.InsuranceData = res.data.response.data;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    inputAllMoney(name) {
      if (!validate.validateIsMoney(this.SakaryAllForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "未锁定") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }

      this.PId = row.Id;
      this.getInsuranceData();
      this.InsuranceVisible = true;
    },
    handleInEdit() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      //    if (this.currentRow.State != "未锁定") {
      //        this.$message({
      //        message: "已锁定状态的信息不允许修改",
      //        type: "error"
      //      });
      //       return;
      //    }
      //    console.log(this.currentRow)
      this.SakaryForm = Object.assign({}, row);
      this.SakaryVisible = true;
    },
    handleAllEdit() {
      (this.SakaryAllForm = {
        FactoryLarge: "",
        UserLarge: "",
        PId: "",
        UserList: [],
      }),
        (this.SakaryAllForm.PId = this.PId);
      this.SakaryAllFormVisible = true;
    },
    handleAdd() {
      AddInsuranceMonth().then((res) => {
        if (res.data.success) {
          this.$message({
            message: "成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData();
        } else {
          this.$message({
            message: "失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "已锁定") {
        this.$message({
          message: "锁定状态的数据不允许删除！",
          type: "error",
        });
        return;
      }
      let para = {
        id: row.Id,
      };

      DeleteInsuranceMonth(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData();
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    //导出增员
    excelIncrease() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要导出的月份数据！",
          type: "error",
        });
        return;
      }
      GetPersonAddExcelList({ date: row.CreateDate }).then((res) => {
        if (res.data.success) {
          console.log(res);
          var header = [
            "姓名",
            "公司",
            "身份证号",
            "民族",
            "性别",
            "文化程度",
            "手机号",
            "联系人",
            "联系电话",
            "家庭地址",
            "通讯地址",
            "户口性质",
            "婚姻状况",
            "增员日期",
            "增员原因",
            "增员参加工作日期",
            "缴费单位",
            "缴费基数",
            "养老保险",
          ];
          var filter = [
            "UserName",
            "Factory",
            "IDCard",
            "Nationality",
            "Male",
            "TopDiploma",
            "MobilePhone",
            "Emergency",
            "EmergencyPhone",
            "IDAddress",
            "Address",
            "NativeStatus",
            "MarriedStatus",
            "AddDateString",
            "AddReason",
            "WorkDateString",
            "PaymentAccount",
            "BaseSalary",
            "Syst",
          ];
          var name = "增员列表";
          this.excelData(res.data.response, header, filter, name);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    excelDownsiz() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要导出的月份数据！",
          type: "error",
        });
        return;
      }
      GetPersonReduceExcelList({ date: row.CreateDate }).then((res) => {
        if (res.data.success) {
          console.log(res);
          var header = [
            "姓名",
            "身份证号",
            "减员日期",
            "减员原因",
            "参加工作日期",
            "缴费单位",
            "月缴费基数",
          ];
          var filter = [
            "UserName",
            "IDCard",
            "ReduceDateString",
            "ReduceReason",
            "WorkDateString",
            "PaymentAccount",
            "BaseSalary",
          ];
          var name = "减员列表";
          this.excelData(res.data.response, header, filter, name);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    excelUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要导出的月份数据！",
          type: "error",
        });
        return;
      }
      GetPersonHistoryList({ monthId: row.Id }).then((res) => {
        if (res.data.success) {
          console.log(res);
          var header = [
            "姓名",
            "公司",
            "身份证号",
            "部门",
            "民族",
            "性别",
            "生日",
            "缴费单位",
            "养老保险(公司)",
            "医疗保险(公司)",
            "失业保险(公司)",
            "工伤保险(公司)",
            "生育保险(公司)",
            "大额医疗(公司)",
            "养老保险(个人)",
            "医疗保险(个人)",
            "失业保险(个人)",
            "工伤保险(个人)",
            "生育保险(个人)",
            "大额医疗(个人)",
          ];
          var filter = [
            "UserName",
            "Factory",
            "IdCard",
            "Dpt",
            "Nationality",
            "Male",
            "Birthday",
            "PaymentAccount",
            "Factory1",
            "Factory2",
            "Factory3",
            "Factory4",
            "Factory5",
            "FactoryLarge",
            "User1",
            "User2",
            "User3",
            "User4",
            "User5",
            "UserLarge",
          ];
          var name = "社保缴纳人员列表";
          this.excelData(res.data.response, header, filter, name);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    excelData(res, header, filter, name) {
      require.ensure([], () => {
        const {
          export_json_to_excel,
        } = require("../../../util/Export2Excel.js");
        const tHeader = header;
        const filterVal = filter;
        const list = res;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, name);
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    handleLock() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要锁定的一行数据！",
          type: "error",
        });
        return;
      }
      this.$confirm("确定要锁定吗？", "提示", {}).then(() => {
          UpdateSetInfoSate({ id: row.Id, IsLock: true}).then((res) => {
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.getData(false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
      });
      },
      handleUnLock() {
          let row = this.currentRow;
          if (!row) {
              this.$message({
                  message: "请选择要锁定的一行数据！",
                  type: "error",
              });
              return;
          }
          this.$confirm("确定要解锁锁吗？", "提示", {}).then(() => {
              UpdateSetInfoSate({ id: row.Id, IsLock: false }).then((res) => {
                  if (res.data.success) {
                      this.$message({
                          message: "修改成功",
                          type: "success",
                          duration: 5000,
                      });
                      this.getData(false);
                  } else {
                      this.$message({
                          message: res.data.msg,
                          type: "error",
                          duration: 5000,
                      });
                  }
              });
          });
      },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          UpdateInsuranceReport(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 5000,
              });
              this.SakaryVisible = false;
              this.getInsuranceData();
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
                duration: 5000,
              });
            }
          });
        });
      });
    },
    onAllSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          this.SakaryAllForm.UserList = this.multipleSelection;
          let para = Object.assign({}, this.SakaryAllForm);
          console.log(this.SakaryAllForm);
          //   console.log(this.multipleSelection)
          UpdateInsuranceReportSalary(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 5000,
              });
              this.SakaryAllFormVisible = false;
              this.getInsuranceData();
            } else {
              this.$message({
                message: res.data.masg,
                type: "error",
                duration: 5000,
              });
            }
          });
        });
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

